<template>
  <div class="card">
    <div class="card-body">
      <!-- Spline Area chart -->
      <h4 class="card-title">Hour Trend</h4>
      <VueApexCharts
        class="apex-charts"
        height="350"
        type="area"
        dir="ltr"
        :series="series"
        :options="chartOptions"
        :key="key"
      ></VueApexCharts>
    </div>
  </div>
</template>
<script>
import appConfig from "@/app.config";
import VueApexCharts from "vue-apexcharts";
import filterMixins from "../../../mixins/filterData";

export default {
  props: ["filters"],
  mixins: [filterMixins],
  components: {
    VueApexCharts,
  },
  data() {
    return {
      title: "Live Registrations - Hour Trend",
      series: [
        {
          name: "Users",
          data: [],
        },
      ],
      chart: {
        toolbar: {
          show: false,
        },
      },
      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        colors: ["#556ee6", "#34c38f"],
        xaxis: {
          type: "date",
          categories: ["Januaray"],
        },
      },
      key: 0,
      loading: true,
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getUserHourTrend();
      },
    },
  },
  created() {
    // this.init();
  },
  methods: {
    init() {
      // this.loading = true;
      // this.loading = false;
    },
    async getUserHourTrend() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/users/hour/count",
          {
            params: searchfiltersParams,
          }
        );
        let time = [];
        this.chartOptions.xaxis.categories = [];
        for (let index = 0; index < 24; index++) {
          this.chartOptions.xaxis.categories.push(
            "" + index + ":00 - " + (parseInt(index) + 1) + ":00"
          );
          time.push(index);
        }
        this.series[0].data = [];
        time.forEach((c) => {
          let d = response.data.hour.filter((v) => v.hour == c);
          let users = d.length > 0 ? d[0].total_users : 0;
          this.series[0].data.push(users);
        });
        this.key += 1;
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getUserHourTrend();
  },
};
</script>
